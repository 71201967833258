<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3 "><i class="fa fa-copy"></i> Campaign Copy Wizard</h2>
        <div class="container-fluid " style="background-color: white; padding: 2%">
        <div class="row ">
        <div class="col-12 pb-3">
            <b-button  class="btn btn-secondary btn-sm text-white font-weight-bold" :to="{ path: '/campaigns' }">
                Back to Campaigns</b-button>
        </div>


        </div>

        <div class="row" v-if="campaign">

            <div class="col-12 col-sm-12 p-5">
                <h4 class="text-center pb-3 border-bottom">Campaign: {{campaign.name}} / #{{campaign._id}}</h4>
                <div v-if="!action" class="text-center p-2">
                    <div>
                        <span class="mr-1"><b>Url</b>: {{campaign.domain}}</span>
                        <span class="mr-1"><b>Status</b>: {{campaign.status}}</span>
                    </div>
                    <hr/>

                    <div class="p-4 row">
                        <div class="col-12 col-sm-12 col-md-12">
                            <h5>What type of copy do you need?</h5>
                            <div class="pt-2 pb-5 form-group">
                                <input type="button" class="btn btn-dark mr-1 " value="Basic" @click="
                           $router.push({path: '/campaigns',query: { campaign: campaign._id },})"/>
                                <input type="button" class="btn btn-success " value="Advanced" @click="setAction('proxy')"/>



                            </div>
                        </div>

                    </div>
                </div>

                <form class="form" role="form" style="" @submit="sav" v-if="action=='basic'">
                    <div class="row">
                        <div class="col-12 col-sm-8">
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" v-model="item.name" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Domain</label>
                                <input type="text" v-model="item.domain" class="form-control required" required/>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Type</label>
                                <select v-model="item.type" class="form-control text-capitalize required" required>
                                    <option value="">-- Type --</option>
                                    <option value="domain">Domain</option>
                                    <!--<option value="loadtest">Load Testing</option>-->
                                    <!--<option value="workflows">Workflows</option>-->
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Status</label>
                                <select v-model="item.status" class="form-control required" required>
                                    <option value="">-- Status --</option>
                                    <option value="active">Active</option>
                                    <option value="proxy">Proxy</option>
                                    <option value="pending">Pending</option>
                                    <option value="disabled">Disabled</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Client</label>
                                <input type="text" v-model="item.client" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Email Address</label>
                                <input type="email" v-model="item.email" class="form-control required" required/>
                            </div>

                            <div class="form-group">
                                <label>Start Date</label>
                                <input type="date" v-model="item.startdate" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>End Date</label>
                                <input type="date" v-model="item.enddate" class="form-control"/>
                            </div>

                            <div class="form-group">
                                <label>Crawl Threshold</label>
                                <input type="number" v-model="item.threshold" class="form-control required" min="1" value="10" required/>
                                <div class="form-text text-muted">Maximum number of crawl nodes that should run at once. Recommended 25.</div>
                            </div>

<!--                            <div class="form-group">-->
<!--                                <label>Strict</label>-->
<!--                                <select v-model="item.geo.strict" class="form-control text-capitalize">-->
<!--                                    <option value="yes">Yes</option>-->
<!--                                    <option value="state">State</option>-->
<!--                                    <option value="city">City</option>-->
<!--                                    <option value="no">No</option>-->
<!--                                </select>-->
<!--                            </div>-->

                            <div class="form-group">
                                <label>Limiter</label>
                                <select v-model="item.limiter" class="form-control">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>

                            <div class="form-group mr-1">
                                <label>Provider</label>
                                <select v-model="item.provider" class="form-control">
                                    <option selected value="">--Any Provider--</option>
                                    <option value="didsoft">DidSoft</option>
                                    <option value="crawlera">Crawlera</option>
                                    <option value="flipnode">FlipNode</option>
                                    <option value="homeip">HomeIp</option>
                                    <option value="luminate">Luminate</option>
                                    <option value="proxyrack">Proxyrack</option>
                                    <option value="smartproxy">Smartproxy</option>
                                    <option value="netnut">Netnut</option>
                                    <option value="proxyguys">ProxyGuys</option>
                                    <option value="spider">Spider</option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label>Crawl Throttling</label>
                                <select v-model="item.throttle" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Adjusts the crawl threshold based on each node's resources.</div>
                            </div>

                            <div class="form-group">
                                <label>Time on Site</label>
                                <input type="number" v-model="item.timeonsite" class="form-control required" min="0" value="1" required/>
                                <div class="form-text text-muted">Maximum time on the site in minutes.</div>
                            </div>

                            <div class="form-group">
                                <label>Time on Site Variation</label>
                                <select v-model="item.timeonsite_variation" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Adjusts the tos to add enthropy.</div>
                            </div>

                            <div class="form-group">
                                <label>Target Clicks</label>
                                <input type="number" v-model="item.target" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum clicks on this campaign.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Max Daily Clicks</label>
                                <input type="number" v-model="item.clicksdaily" class="form-control" min="0" max="999999999" value="0"/>
                            </div>

                            <!--<div class="form-group">
                                <label>Max Retries</label>
                                <input type="number" v-model="item.retries" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum number of times to retry</div>
                            </div>-->
                        </div>

                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Local Links Only</label>
                                <select v-model="item.localLinks" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                                <div class="form-text text-muted">Only allow links from the campaign domain.</div>
                            </div>

                            <div class="form-group">
                                <label>Link Level</label>
                                <input type="number" v-model="item.linkLevel" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum depth of links to follow this campaign.</div>
                            </div>

                            <div class="form-group">
                                <label>Local Links Navigation Alternative</label>
                                <select v-model="item.alternative" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                <div class="form-text text-muted">Use page.goto instead of anchor click to navigate.</div>
                            </div>

                            <div class="form-group">
                                <label>Bounce</label>
                                <select v-model="item.bounce" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                                <div class="form-text text-muted">Allows the campaign to bounce a modulus of the traffic to the target.</div>
                            </div>

                            <div class="form-group">
                                <label>Behaviors</label>
                                <input type="text" v-model="item.behaviors" class="form-control" value="2500,7000,5000,8500,15000"/>
                                <div class="form-text text-muted">The list of wait times that the system can shuffle through in miliseconds. 1 sec = 1000ms.</div>
                            </div>

                            <div class="form-group">
                                <label>Navigation Timeout</label>
                                <input type="number" v-model="item.timeout" class="form-control" min="0" value="0"/>
                                <div class="form-text text-muted">Maximum amount of time to wait on page navigation.</div>
                            </div>

                            <div class="form-group">
                                <label>Wait Until</label>
                                <select v-model="item.waitUntil" class="form-control text-capitalize">
                                    <option value="load">Load event is fired</option>
                                    <option value="domcontentloaded">DOMContentLoaded event is fired</option>
                                    <option value="networkidle0">0 Network connections</option>
                                    <option value="networkidle2">&lt;&equals; 2 Network connections</option>
                                </select>
                                <div class="form-text text-muted">When to consider navigation succeeded.</div>
                            </div>

                            <!--<div class="form-group">
                                <label>Backoff Factor</label>
                                <input type="number" v-model="item.backoff" class="form-control" step="0.01" min="0" value="0"/>
                                <div class="form-text text-muted">Backoff factoring value</div>
                            </div>-->
                        </div>

                        <div class="col-12 col-sm-4" v-if="item.type == 'workflows'">
                            <workflow></workflow>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 col-8">
                            <div class="form-group" >
                                <label>Associated E-Campaigns</label>
                                <textarea rows="5" v-model="item.ecampaigns" class="form-control" placeholder="Associated E-Campaign geos"></textarea>
                                <div class="form-text text-muted">The comma separated list of e-campaign geos associated with this campaign.</div>
                            </div>
                            <div class="form-group">
                                <label>Referers</label>
                                <textarea rows="5" v-model="item.referers" class="form-control" placeholder="Referers"></textarea>
                                <div class="form-text text-muted">The comma separated list of paths to avoid.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Blacklist</label>
                                <textarea rows="5" v-model="item.blacklist" class="form-control" placeholder="Blacklisted paths"></textarea>
                                <div class="form-text text-muted">The comma separated list of paths to avoid.</div>
                            </div>

                            <div class="form-group" v-if="item.type != 'loadtest'">
                                <label>Use Proxy</label>
                                <select v-model="item.proxy" class="form-control text-capitalize">
                                    <option value="no">No</option>
                                    <option value="yes">Yes</option>
                                </select>
                            </div>

                            <div v-if="item.proxy == 'yes'">
                                <!--<div class="form-group">
                                    <label>Clicks Per IP</label>
                                    <input type="number" v-model="item.proxy_clicksperip" class="form-control" min="0" max="999999999" value="0"/>
                                </div>
                                <div class="form-group">
                                    <label>Proxy Retries</label>
                                    <input type="number" v-model="item.proxy_retries" class="form-control" min="0" max="999999999" value="0"/>
                                </div>-->
                                <div class="form-group">
                                    <label>Proxy Countries</label>
                                    <textarea rows="5" v-model="item.proxy_countries" class="form-control" placeholder="Allowed Proxy Countries"></textarea>
                                    <div class="form-text text-muted">The comma separated list of countries to use proxies from. Specific providers and be specified by prefixing the entry with the name of the provider ex: luminate:US/FL/Miami.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group text-right">
                                <button type="button" class="btn btn-secondary btn-sm mr-1" @click="action=''"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                                <button type="submit" class="btn btn-success" :disabled="!item.domain || (item.type == 'workflow' && (!item.workflows || !item.workflows.length))"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                            </div>
                        </div>
                    </div>
                </form>

                <form class="form" role="form" style="" @submit.prevent ="process" prevent de v-if="action=='proxy'">
                    <div class="row">
                        <div class="col-12 col-md-5">
                            <h5>Build your proxy selection</h5>
                            <div class="form-group">
                                <label>Provider</label>
                                <select v-model="item.provider" class="form-control text-capitalize" @change="loadStates(item.provider)">
                                    <option value="">-- Provider --</option>
                                    <option v-for="provider in providers" :key="provider" :value="provider.value">{{provider.text}}</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="item.provider">
                                <label>Provider State</label>
                                <select v-model="item.state" class="form-control text-capitalize" @change="loadCities(item.provider, item.state)">
                                    <option value="">-- Provider State --</option>
                                    <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                                </select>
                            </div>

                            <div class="form-group" v-if="item.provider && item.state">
                                <label>Provider Cities</label>
                                <p>Select the cities that you want to include</p>
                                <div class="button-group mb-2">
                                    <button type="button" class="btn btn-sm btn-secondary mr-1" @click="selectAll">Select all</button>
                                    <button type="button" class="btn btn-sm btn-secondary mr-1" @click="selectNone">Select None</button>
                                </div>
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="city in cities" :key="city">
                                        <input type="checkbox" class="mr-1" v-model="item.proxy" :value="city"/> <span><b>{{city}}</b></span>
                                    </li>
                                </ul>

                            </div>
                            <button type="button" class="btn btn-success" :disabled="!item.provider" @click="addPGeo">Add Geo</button>
                        </div>
                        <div class="col-12 col-md-7">
                            <h5>Selected Proxies</h5>
                            <div class="form-group">
                                <ul class="list-group">
                                    <li class="list-group-item" v-for="(geos,idx) in item.geos" :key="geos">
                                        <button type="button" class="btn btn-danger btn-sm mr-1" @click="removeProxy(idx)">Remove</button>
                                        <b>{{`Country: ${geos.country}, State: ${geos.state}, City: ${geos.city}`}}</b>
                                    </li>
                                </ul>
                            </div>
                            <div class="form-group">
                                <label>Proxy Mode</label>
                                <select v-model="item.mode" class="form-control text-capitalize required" required>
                                    <option value="single">Single Campaign</option>
                                    <option value="multiple">Multiple Campaigns</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label>Duplicate Check</label>
                                <select v-model="item.check" class="form-control text-capitalize">
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="alert alert-warning mb-2 mt-2" v-if="item.mode == 'single' && item.geos.length > 10">
                                <h6>Campaign Alert</h6>
                                <p>You cannot create a campaign that has more than 10 proxies on the same campaign. You must use Multiple Campaigns for this type of configuration</p>
                            </div>

                            <div class="form-group text-right">
                                <button type="button" class="btn btn-secondary btn-sm mr-1" @click="action=''">Cancel</button>
                                <button type="submit" class="btn btn-success" :disabled="!item.mode || !item.geos || (item.geos && !item.geos.length) || (item.mode == 'single' && item.geos && item.geos.length > 10)">Process</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
<!--        <div class="container-fluid vh-100" style="background-color: white;"></div>-->

    </div>
</template>

<script>
    import axios from 'axios'
    import swal from 'sweetalert'

    export default {
        name: 'Campaign',
        props:['user'],
        data: function () {
            return {
                action:'',
                campaign:null,
                item: {},
                providers:[],
                states:[],
                cities:[],
                preloader: false
            }
        },
        created: function(){
            this.load().then(this.loadProxies());
        },
        methods:{
            setAction: function(action){
                if(action == 'basic') {
                    this.item = Object.assign({}, this.campaign);
                    delete this.item._id;
                } else {
                    this.item = {mode:'single', provider: '', country: 'US', state: '', proxy:[], proxies: [],geos:[]};
                }

                this.action = action;
                this.$forceUpdate();
            },
            selectAll: function(){
                this.item.proxy = [];
                for(var i=0;i<this.cities.length;i++)
                    this.item.proxy.push(this.cities[i]);
            },
            selectNone: function(){
                this.item.proxy = [];
            },
            addProxy: function(){
                //Build the proxy entry
                if(this.item.provider && !this.item.state && !this.item.proxy.length){
                    this.item.proxies.push(`${this.item.provider}:US`);
                }

                if(this.item.provider && this.item.state && !this.item.proxy.length){
                    this.item.proxies.push(`${this.item.provider}:US/${this.item.state}`);
                }

                if(this.item.provider && this.item.state && this.item.proxy.length) {
                    for (var i = 0; i < this.item.proxy.length; i++) {
                        this.item.proxies.push(`${this.item.provider}:US/${this.item.state}/${this.item.proxy[i]}`);
                    }
                }
                console.log(this.item.proxies)

                this.item.provider = '';
                this.item.state = '';
                this.item.proxy = [];
            },

            addPGeo: function(){
                //Build the geo entry

                if(this.item.provider && !this.item.state && !this.item.proxy.length){

                    this.item.geos.push(Object.assign({}, {country: 'US', state:'',city:'' }));
                }

                if(this.item.provider && this.item.state && !this.item.proxy.length){
                    this.item.geos.push(Object.assign({}, {country:'US',state:this.item.state, city:''}));

                }

                if(this.item.provider && this.item.state && this.item.proxy.length) {
                    for (var i = 0; i < this.item.proxy.length; i++) {
                        // this.item.proxies.push(`${this.item.provider}:${this.item.state}/${this.item.proxy[i]}`);
                        this.item.geos.push(Object.assign({}, {country: 'US',state:this.item.state, city:this.item.proxy[i]}))
                    }
                }


                // this.item.provider = '';
                this.item.state = '';
                this.item.proxy = [];
                this.item.campaign_id = this.campaign._id
            },

            removeProxy: function(i){
                //Remove the proxy from the list
                this.item.geos.splice(i, 1);
            },
            process: function(){
                //Handle cloning the campaign to each proxy
                if(!this.item.geos || (this.geos && !this.geos.length)){
                    //Hand the error notification
                    return false;
                }

                var request = {'apikey': this.$root.apikey,campaign_id:this.campaign._id,  id: this.campaign._id, mode: this.item.mode, provider:this.item.provider, geos: this.item.geos, check: this.item.check||'no'};
                // this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/campaigns/clone`, data:request, responseType:'json', method:'POST'}).then(function(resp){
                        //Process the results
                        this.$root.preloader = false
                        if(resp.data && !resp.data.error){
                            //Saved successfully, now update the parent
                            swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                                if(val) {
                                    window.close();
                                    location.reload()
                                }
                            }.bind(this))
                        } else {
                            //Handle errors
                            //this.errormsg = (resp.data && resp.data.error)? resp.data.error : false
                            swal({title:"Error", text:resp.data.error.message, icon:"error"})
                            this.$root.preloader = false
                        }
                    }.bind(this)).catch(function(err){
                        this.$root.preloader = false
                        this.errormsg = err.message||"An unexpected error occured"
                        swal({title:"Error", text: this.errormsg, icon:"error"})
                    }.bind(this))
            },
            save: function(){
                //Handle saving the changes
                var request = {'apikey': this.$root.apikey, id:this.item._id, args:this.item,params: this.$route.params.id};
                // this.$root.preloader = true

                axios({url:`${this.$root.serverUrl}/admin/campaigns`, data:request, responseType:'json', method:'PUT'}).then(function(resp){
                        //Process the results
                        this.$root.preloader = false
                        if(resp.data && !resp.data.error){
                            //Saved successfully, now update the parent
                            swal({title:"Notice", text:"Your changes were saved successfully", icon:"success"}).then(function(val){
                                if(val) {
                                    window.close();
                                }
                            }.bind(this))
                        } else {
                            //Handle errors
                            this.errormsg = (resp.data && resp.data.error)? resp.data.error : false
                            this.$root.preloader = false
                        }
                    }.bind(this)).catch(function(err){
                        this.$root.preloader = false
                        this.errormsg = err.message||"An unexpected error occoured"
                        swal({title:"Error", text: this.errormsg, icon:"error"})
                    }.bind(this))
            },
            load: function(){
                //Load the campaign
                var request = {'apikey': this.$root.apikey};
                // this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/${this.$route.params.id}/id`, {params:request}).then(function(resp){
                    this.campaign = (resp.data && resp.data.data)? resp.data.data : null;
                    this.$root.preloader = false;
                }.bind(this));
            },
            loadProxies: function(){
                //Load the proxies
                var request = {'apikey': this.$root.apikey};
                // this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/dropdown/provider`, {params:request}).then(function(resp){
                    //Store the stats
                    this.providers = (resp.data && resp.data.data)? resp.data.data : [];
                    this.$root.preloader = false;
                }.bind(this));
            },
            loadStates: function(provider){
                //Load the proxy states
                if(!provider)
                    return Promise.reject(new Error('Missing provider'));

                var request = {'apikey': this.$root.apikey};
                // this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies/${provider}/US/states`, {params:request}).then(function(resp){
                    //Store the stats
                    this.states = (resp.data && resp.data.data)? resp.data.data : [];
                    this.$root.preloader = false;
                }.bind(this));
            },
            loadCities: function(provider, state){
                //Load the proxy state cities
                if(!provider)
                    return Promise.reject(new Error('Missing provider'));
                if(!state)
                    return Promise.reject(new Error('Missing provider state'));

                var request = {'apikey': this.$root.apikey};
                // this.$root.preloader = true;

                return axios.get(`${this.$root.serverUrl}/admin/campaigns/proxies/${provider}/US/${state}/cities`, {params:request}).then(function(resp){
                    this.cities = (resp.data && resp.data.data)? resp.data.data : [];
                    this.$root.preloader = false;
                }.bind(this));
            },
        }
    }
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 300px;
    }
    .ul li {
        width:10em;
        float:left;
    }
    /* Small devices (landscape phones, 544px and up) */
    /*@media (min-width: 310px) {*/
    /*    h4 {font-size:1rem;}*/
    /*    !*h2 {font-size:1.5rem;}!*1rem = 16px*!*!*/
    /*}*/

    /*!* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint *!*/
    /*@media (min-width: 768px) {*/
    /*    h2 {font-size:2rem;} !*1rem = 16px*!*/
    /*}*/

    /*!* Large devices (desktops, 992px and up) *!*/
    /*@media (min-width: 992px) {*/
    /*    h2 {font-size:2.5rem;} !*1rem = 16px*!*/
    /*}*/

    /*!* Extra large devices (large desktops, 1200px and up) *!*/
    /*@media (min-width: 1200px) {*/
    /*    h2 {font-size:3rem;} !*1rem = 16px*!*/
    /*}*/
</style>
